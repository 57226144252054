import React from "react";
import Image from "next/image";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

const Slideshow = ({ slice }) => {
  const bulletClasses = `mr-2.5 w-3 h-3 bg-brand-light-blue-nav rounded-full slideshow__bullet ${
    slice.items.length == 1 ? "hidden" : ""
  }`;
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<button class="' + className + " " + bulletClasses + '">' + "</button>"
      );
    },
  };

  return (
    <section className="container max-w-screen-lg slideshow clip-path-global px-8 lg:px-0">
      <Swiper pagination={pagination} modules={[Pagination]}>
        {slice?.items &&
          slice?.items?.map((item, i) => (
            <SwiperSlide key={i}>
              <Image
                src={item?.img?.url}
                alt={item?.img?.alt}
                width={item?.img?.dimensions?.width}
                height={item?.img?.dimensions?.height}
                className="clip-path-global"
                quality={100}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </section>
  );
};

export default Slideshow;
