import React from "react";
import Image from "next/image";
import Paragraph, {
  ParagraphType,
  ParagraphVariant,
} from "../../components/typography/Paragraph";
import LinkComponent, { LinkType, LinkVariant } from "../../components/UI/Link";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import { linkResolver } from "../../../prismicio";
import Container from "../../components/UI/Container";
import Logo from "../../components/UI/Logo";

const Footer = ({ slice, context }) => {
  const socials = context?.footerSections?.filter(
    (slice) => slice.slice_type === "socials"
  )[0];
  const location = context?.footerSections?.filter(
    (slice) => slice.slice_type === "location"
  )[0];
  const contact = context?.footerSections?.filter(
    (slice) => slice.slice_type === "contact"
  )[0];
  const links = context?.globalSettings?.data?.slices?.filter(
    (slice) => slice.slice_type === "navigation"
  )[0];

  return (
    <Container className="pt-12">
      <div className="grid grid-cols-12 gap-y-5 lg:gap-y-0">
        <div className="col-span-12 md:col-span-9 xl:col-span-3">
          <div className="h-6 mb-8">
            <Logo />
          </div>
          {slice?.primary?.description &&
            slice?.primary.description.map((item, i) => (
              <Paragraph
                paragraphVariant={ParagraphVariant.WHITE}
                paragraphType={ParagraphType.PARAGRAPH_7}
                key={i}
                className={`${
                  i == slice?.primary?.description?.length ? "" : "mb-4"
                }`}
              >
                {item?.text}
              </Paragraph>
            ))}
        </div>

        <div className="col-span-12 grid grid-cols-1 gap-12 lg:mt-14 lg:grid-cols-3 xl:col-span-8 xl:col-start-5 xl:grid-cols-3">
          <div className="col-span">
            <Heading
              level={Level.HEADING_2}
              headingVariant={HeadingVariant.WHITE}
              className="mb-4"
            >
              {links?.primary?.title}
            </Heading>

            <nav className="grid xl:grid-cols-2 gap-x-14 gap-y-5 w-fit">
              {links &&
                links?.items?.map((item, i) => {
                  return (
                    <LinkComponent
                      className={"text-sm hover:underline"}
                      linkVariant={LinkVariant.WHITE}
                      linkType={LinkType.LINK_4}
                      link={linkResolver(item.link)}
                      key={i}
                    >
                      {item?.label[0]?.text}
                    </LinkComponent>
                  );
                })}
            </nav>
          </div>
          <div className="col-span">
            <Heading
              level={Level.HEADING_2}
              headingVariant={HeadingVariant.WHITE}
              className="mb-4"
            >
              {location?.primary?.title[0]?.text}
            </Heading>

            <address className="grid not-italic grid-cols-1 gap-y-5">
              {location &&
                location?.items?.map((item, i) => (
                  <Paragraph
                    paragraphVariant={ParagraphVariant.WHITE}
                    paragraphType={ParagraphType.PARAGRAPH_4}
                    key={i}
                  >
                    {item.location[0].text}
                  </Paragraph>
                ))}
            </address>
          </div>
          <div className="col-span flex flex-col items-flex-end justify-flex-end">
            <Heading
              level={Level.HEADING_2}
              headingVariant={HeadingVariant.WHITE}
              className="mb-4"
            >
              {contact?.primary?.title[0]?.text}
            </Heading>

            <nav className="grid grid-cols-1 gap-y-5">
              {contact &&
                contact.items?.map((item, i) => (
                  <div
                    key={i}
                    className="flex items-center cursor-pointer"
                    onClick={() =>
                      (window.location = item?.text[0]?.spans[0]?.data.url)
                    }
                  >
                    <div className="mr-3.5">
                      <Image
                        src={item?.icon?.url}
                        alt={item?.icon?.alt}
                        width={item?.icon?.dimensions?.width}
                        height={item?.icon?.dimensions?.height}
                        quality={100}
                      />
                    </div>

                    <Paragraph
                      paragraphVariant={ParagraphVariant.WHITE}
                      paragraphType={ParagraphType.PARAGRAPH_4}
                      className="mr-14 hover:underline"
                    >
                      {item?.text[0]?.text}
                    </Paragraph>
                  </div>
                ))}
            </nav>
          </div>
        </div>
      </div>

      <div className="py-6 relative flex justify-between mt-12">
        <span className="block w-full h-px bg-white absolute top-0 left-0 right-0 opacity-30"></span>

        <div className="flex flex-col md:flex-row">
          <Paragraph
            paragraphVariant={ParagraphVariant.WHITE}
            paragraphType={ParagraphType.PARAGRAPH_4}
            className="mr-14 flex items-center mb-2 md:mb-0"
          >
            {slice?.primary?.copyright[0]?.text}
          </Paragraph>

          <nav className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-14">
            {context?.footer &&
              context?.footer?.items?.map((item, i) => (
                <LinkComponent
                  linkVariant={LinkVariant.WHITE}
                  linkType={LinkType.LINK_4}
                  link={linkResolver(item.link)}
                  key={i}
                  target="_blank"
                >
                  {item?.label[0]?.text}
                </LinkComponent>
              ))}
          </nav>
        </div>

        <div className="grid grid-cols-3 gap-x-3.5">
          {socials &&
            socials?.items.map((item, i) => (
              <LinkComponent
                linkVariant={LinkVariant.WHITE}
                linkType={LinkType.LINK_4}
                link={linkResolver(item.link)}
                key={i}
                target="_blank"
              >
                <Image
                  src={item.icon.url}
                  alt={item.icon.alt}
                  width={item.icon.dimensions.width}
                  height={item.icon.dimensions.height}
                  quality={100}
                />
              </LinkComponent>
            ))}
        </div>
      </div>
    </Container>
  );
};

export default Footer;
