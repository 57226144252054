import React from "react";
import { classNames } from "../../utils/classNames";
import Arrow from "./Arrow";

export enum LinkType {
  LINK_1,
  LINK_2,
  LINK_3,
  LINK_4,
  LINK_5,
}

type PageCountIndication = {
  enabled: boolean;
  count: number;
};

interface LinkProps {
  linkVariant: LinkVariant;
  children: React.ReactNode;
  className?: string;
  displayArrowRight?: boolean;
  displayArrowLeft?: boolean;
  arrowType?: LinkArrow;
  notification?: PageCountIndication;
  link: string;
  linkType?: LinkType;
  routerPath?: string;
  target?: string;
}

export enum LinkVariant {
  BLUE,
  DARK_BLUE,
  WHITE,
  NAV_LINK,
  BREADCRUMB,
  BREADCRUMB_ACTIVE,
}

export enum LinkArrow {
  PRIMARY,
}

const LINK_ARROW_MAPS: Record<LinkArrow, React.ReactNode> = {
  [LinkArrow.PRIMARY]: (
    <Arrow styles="bg-red hover:animate-pulse link__arrow--right" />
  ),
};

const LINK_VARIANT_MAPS: Record<LinkVariant, string> = {
  [LinkVariant.BLUE]: "text-brand-blue-light hover:text-brand-faded-blue",
  [LinkVariant.DARK_BLUE]: "text-brand-dark-blue",
  [LinkVariant.WHITE]: "text-white",
  [LinkVariant.BREADCRUMB]: "text-gray-400",
  [LinkVariant.NAV_LINK]: "text-white font-semibold",
  [LinkVariant.BREADCRUMB_ACTIVE]: "text-white",
};

const LINK__TYPE_MAPS: Record<LinkType, string> = {
  [LinkType.LINK_1]: "font-bold",
  [LinkType.LINK_2]: "font-bold text-lg",
  [LinkType.LINK_3]: "font-medium",
  [LinkType.LINK_4]: "text-sm",
  [LinkType.LINK_5]: "text-base",
};

const Link: React.FC<LinkProps> = ({
  linkVariant,
  children,
  className,
  displayArrowLeft,
  displayArrowRight,
  arrowType,
  routerPath,
  notification,
  link,
  linkType,
  target,
}) => {
  /* If the linkType is not defined, it will default to LinkType.LINK_1. */
  if (!linkType) linkType = LinkType.LINK_1;

  /* Returning a link. */
  return (
    <a
      className={classNames(
        LINK__TYPE_MAPS[linkType],
        LINK_VARIANT_MAPS[
          routerPath?.replaceAll("/", "") === link
            ? LinkVariant.BLUE
            : linkVariant
        ],
        className,
        displayArrowLeft || displayArrowRight ? `hover:translate-x-2.5` : "",
        "space-x-2.5",
        "flex items-center link"
      )}
      href={link}
      target={target}
    >
      {displayArrowLeft && LINK_ARROW_MAPS[arrowType || LinkArrow.PRIMARY]}
      <span>{children}</span>
      {notification?.enabled && (
        <span className="bg-white text-black text-[0.6rem] flex leading-4 justify-center w-4 h-4 -mt-2 ml-2 -pr-6 rounded-full">
          {notification?.count?.toString()}
        </span>
      )}
      {displayArrowRight && LINK_ARROW_MAPS[arrowType || LinkArrow.PRIMARY]}
    </a>
  );
};

export default Link;
