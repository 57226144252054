import React, { useState } from "react";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Paragraph, {
  ParagraphVariant,
} from "../../components/typography/Paragraph";
import Button, { ButtonVariant } from "../../components/UI/Button";
import { classNames } from "../../utils/classNames";
import Container from "../../components/UI/Container";

const ContactForm = ({ slice }) => {
  const [submission, setSubmission] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    message: "",
    consent: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const inputStyles =
    "w-full py-2.5 pl-2 border-brand-light-gray border border-solid text-sm text-brand-marine placeholder:font-extralight focus:border-brand-blue-light focus:outline-none mt-1";
  const [isButtonDisabled] = useState(false);

  const updateValue = (key, value) => {
    setSubmission((f) => {
      return {
        ...f,
        [key]: value,
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch("/api/forms/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submission),
    })
      .then(async (res) => {
        const resJson = await res.json();

        if (res.ok) {
          setSuccess(slice?.primary?.success_message);
          setError("");
          return;
        }

        setSuccess("");

        if (resJson.issues) {
          setError(slice?.primary?.validation_error_message);
          return;
        }

        if (resJson.message) {
          setError(slice?.primary?.generic_error_message);
        }
      })
      .catch((err) => {
        console.error("An error has occurred ", err);
      });
  };

  return (
    <Container>
      <div className="origin-top-left -ml-8 pl-8 -mr-12 pr-12 pt-10 pb-14 bg-brand-light-blue md:-skew-x-[2deg]">
        <div className="origin-top-left md:skew-x-[2deg]">
          <Heading
            level={Level.HEADING_1}
            headingVariant={HeadingVariant.MARINE}
            className="w-fit mb-6"
          >
            {slice?.primary?.title}
          </Heading>

          {slice?.primary?.description?.map((item, i) => (
            <Paragraph
              paragraphVariant={ParagraphVariant.MARINE}
              key={i}
              className="mb-16"
            >
              {item.text}
            </Paragraph>
          ))}

          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-12 gap-y-6 md:gap-x-8 mb-6 "
          >
            {success && (
              <div className="col-span-12">
                <div className="rounded-md bg-green-50 p-4">
                  <p className="text-sm font-medium text-green-800">
                    {success}
                  </p>
                </div>
              </div>
            )}

            <div className="col-span-12 md:col-span-6" key="firstname">
              <label htmlFor="firstname" className="text-sm text-brand-marine">
                <span className="font-bold">Naam</span>
                <span className="text-xs"> (verplicht)</span>
              </label>

              <input
                defaultValue={submission.firstname}
                type="text"
                id="firstname"
                name="firstname"
                className={classNames(inputStyles)}
                placeholder="Naam"
                required={true}
                onFocus={(e) => {
                  e.target.classList.add("valid:border-brand-marine");
                }}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
              />
            </div>

            <div className="col-span-12 md:col-span-6" key="lastname">
              <label htmlFor="lastname" className="text-sm text-brand-marine">
                <span className="font-bold">Achternaam</span>
                <span className="text-xs"> (verplicht)</span>
              </label>

              <input
                defaultValue={submission.lastname}
                type="text"
                id="lastname"
                name="lastname"
                className={classNames(inputStyles)}
                placeholder="Achternaam"
                required={true}
                onFocus={(e) => {
                  e.target.classList.add("valid:border-brand-marine");
                }}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
              />
            </div>

            <div className="col-span-12 md:col-span-6">
              <label htmlFor="email" className="text-sm text-brand-marine">
                <span className="font-bold">E-mail</span>
                <span className="text-xs"> (verplicht)</span>
              </label>

              <input
                defaultValue={submission.email}
                type="email"
                id="email"
                name="email"
                className={classNames(inputStyles)}
                placeholder="naam@provider.nl"
                required={true}
                onFocus={(e) => {
                  e.target.classList.add("valid:border-brand-marine");
                }}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
              />
            </div>

            <div className="col-span-12 md:col-span-6">
              <label htmlFor="phone" className="text-sm text-brand-marine">
                <span className="font-bold">Telefoonnummer</span>
              </label>
              <input
                defaultValue={submission.phone}
                type="tel"
                id="phone"
                name="phone"
                className={classNames(inputStyles)}
                placeholder="+31 6 123 456 78"
                required={false}
                onFocus={(e) => {
                  e.target.classList.add("valid:border-brand-marine");
                }}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
              />
            </div>

            <div className="col-span-12">
              <label htmlFor="phone" className="text-sm text-brand-marine">
                <span className="font-bold">Bericht</span>
                <span className="text-xs"> (verplicht)</span>
              </label>

              <textarea
                rows="5"
                id="message"
                name="message"
                className={inputStyles}
                placeholder="Waar kunnen wij je mee helpen?"
                required={true}
                defaultValue={submission.message}
                onFocus={(e) => {
                  e.target.classList.add("valid:border-brand-marine");
                }}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
              />
            </div>

            {error && (
              <div className="col-span-12">
                <Paragraph
                  paragraphVariant={ParagraphVariant.RED}
                  className="mb-16"
                >
                  <span className="inline-block w-6 h-6 text-center bg-brand-red text-white rounded-full font-bold mr-3.5">
                    !
                  </span>
                  {error}
                </Paragraph>
              </div>
            )}

            <div className="col-span-12">
              <Button
                type="submit"
                className="disabled:bg-brand-light-gray"
                buttonVariant={ButtonVariant.BUTTON_1}
                tag={"button"}
                disabled={isButtonDisabled || success}
              >
                {slice?.primary?.buttonTitle}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default ContactForm;
