import React from "react";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import { linkResolver } from "../../../prismicio";
import Container from "../../components/UI/Container";
import Arrow from "../../components/UI/Arrow";

const JobCard = ({ props: { title, work_schedule, link } }) => {
  return (
    <li>
      <a
        href={linkResolver(link)}
        className="group -mx-8 drop-shadow-[0px_2px_8px_rgba(0,0,0,0.10)] bg-white flex justify-between"
      >
        <div className="ml-4 md:ml-4 flex justify-between bg-white md:-mr-16 cursor-pointer">
          <div className="flex flex-col lg:flex-row justify-start lg:items-center p-4 md:py-8 md:px-4 lg:space-x-8">
            <div className="font-extrabold text-lg leading-8 uppercase  text-brand-marine">
              {title}
            </div>
            <p className="text-brand-blue-light leading-8 font-bold tracking-normal">
              {work_schedule}
            </p>
          </div>
        </div>
        <div className="flex items-center p-8">
          <Arrow styles="bg-red group-hover:translate-x-2.5 group-hover:animate-pulse link__arrow--right" />
        </div>
      </a>
    </li>
  );
};

const Jobs = ({ slice: { primary, items } }) => {
  return (
    <Container className="grid space-y-3.5 md:px-0">
      {primary?.title[0]?.text && (
        <Heading
          level={Level.HEADING_1}
          headingVariant={HeadingVariant.MARINE}
          className="mb-6 w-fit"
        >
          {primary.title[0].text}
        </Heading>
      )}
      <ul className="grid space-y-3.5 md:px-0">
        {items?.map((data, index) => (
          <JobCard key={index} props={data} />
        ))}
      </ul>
    </Container>
  );
};

export default Jobs;
