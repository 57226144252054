import React from "react";
import Container from "../../components/UI/Container";

const Quotes = ({ slice }) => {
  return (
    <Container className="font-sans bg-brand-light-blue sm:bg-white">
      {slice?.primary?.text && (
        <div className="bg-brand-light-blue relative space-y-8 -mx-8 lg:clip-path-global px-12 py-12 lg:py-16 lg:px-24">
          {slice?.primary?.text.map((item, i) => {
            return (
              <p
                className="text-brand-marine font-extrabold text-lg md:text-3.5xl text-center md:leading-[3rem]"
                key={`quote-paragraph-${i}`}
              >
                {item.text}
              </p>
            );
          })}
        </div>
      )}
    </Container>
  );
};

export default Quotes;
