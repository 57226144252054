import React from "react";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Paragraph, {
  ParagraphType,
  ParagraphVariant,
} from "../../components/typography/Paragraph";
import Container from "../../components/UI/Container";

const UniqueSellingPoints = ({ slice: { items } }) => {
  return (
    <Container className="flex w-full justify-center items-center">
      <div className="-ml-8 pl-8 -mr-12 pr-12 bg-brand-text-background origin-top-left md:-skew-x-[2deg]">
        <div className="grid grid-cols-1 lg:grid-cols-2 md:skew-x-[2deg] origin-top-left">
          {items.map((data, index) => {
            return <SellingPoint key={index} props={data} />;
          })}
        </div>
      </div>
    </Container>
  );
};

const SellingPoint = ({ props: { icon, title, description } }) => (
  <div className="w-full flex justify-start py-8">
    <div className="border border-2 border-brand-blue-light w-16 h-16 px-3 flex justify-center items-center self-stretch">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={icon.url}
        alt={icon.alt}
        className="w-full h-auto object-contains "
      />
    </div>

    <div className="px-6 flex  flex-col items-start text-left flex-1">
      <Heading
        level={Level.HEADING_2}
        headingVariant={HeadingVariant.MARINE}
        className="font-size-[18px] mb-1 uppercase "
      >
        {title}
      </Heading>

      <Paragraph
        paragraphVariant={ParagraphVariant.MARINE}
        paragraphType={ParagraphType.PARAGRAPH_4}
        className="mr-14"
      >
        {description}
      </Paragraph>
    </div>
  </div>
);

export default UniqueSellingPoints;
