import React from "react";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Image from "next/image";
import ArrowExtended from "../../components/UI/ArrowExtended";
import { linkResolver } from "../../../prismicio";

const HighlightedProject = ({ slice: { project } }) => {
  return (
    <div className="container -mt-8">
      <a
        href={linkResolver(project)}
        className="-mx-4 flex flex-col group md:flex-row"
      >
        <div className="md:mr-12">
          <figure className="relative md:w-72 aspect-[3/2] md:aspect-[2/2] clip-path-button">
            <Image
              src={project.data.thumbnail?.url}
              alt={project.data.thumbnail?.alt}
              className="max-w-2xl group-hover:scale-110 origin-center transition-all duration-[3000ms] ease-out"
              style={{ objectFit: "cover" }}
              quality={100}
              sizes={`546px`}
              fill
            />
            <figcaption className="absolute top-10 left-0 z-10 flex h-12 items-center justify-center bg-white pr-6 pl-4 text-left shadow-xl clip-path-button lg:top-16">
              <Heading
                headingVariant={HeadingVariant.MARINE}
                level={Level.HEADING_5}
              >
                {project.data.title[0]?.text}
              </Heading>
            </figcaption>
          </figure>
        </div>
        <div className="flex flex-col justify-center">
          <ul className="order-1 mt-2 leading-6 blue-dots md:order-2 md:mb-8">
            {project.data.tags.map((item, index) => (
              <li
                key={index}
                className="mr-4 after:ml-4 inline-block text-xs md:text-sm"
              >
                {item.tag}
              </li>
            ))}
          </ul>
          <p className="order-2 mt-3 text-lg font-bold text-brand-marine md:font-extra-bold md:order-1 md:mt-0 md:mb-2 md:uppercase lg:text-2xl group-hover:underline decoration-2 underline-offset-4">
            {project.data.tagline[0]?.text}
          </p>
          <ArrowExtended className="order-3 hidden group-hover:ml-4 md:block" />
        </div>
      </a>
    </div>
  );
};

export default HighlightedProject;
