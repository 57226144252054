import React from "react";
import LinkComponent, { LinkType, LinkVariant } from "../../components/UI/Link";
import { linkResolver } from "../../../prismicio";
import { useRouter } from "next/router";
import Container from "../../components/UI/Container";
import BreadCrumbArrow from "../../components/UI/BreadCrumbArrow";

const Breadcrumb = ({ slice: { items } }) => (
  <div className="px-8">
    <Container className="w-full py-8 flex items-center">
      <Links links={items} />
    </Container>
  </div>
);

const Links = ({ links }) => {
  const router = useRouter();
  const page = router.query.page.replace("/", "");
  const subpage = router.query?.subpage?.replace("/", "");

  return (
    <div className={" flex flex-row "}>
      {links.map(({ link, title }, index) => {
        return (
          <LinkComponent
            key={index}
            linkVariant={LinkVariant.BREADCRUMB}
            linkType={LinkType.LINK_4}
            link={link.slug === page + subpage ? link.uid : linkResolver(link)}
            routerPath={router.query.subpage ? subpage : router.asPath}
            className="hover:text-brand-faded-blue"
          >
            <div className="flex">
              <span
                className={
                  links.length - 1 === index
                    ? "font-semibold text-brand-blue-light"
                    : ""
                }
              >
                {title}
              </span>
              <span>
                {links.length - 1 !== index ? <BreadCrumbArrow /> : ""}
              </span>
            </div>
          </LinkComponent>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
