import React from "react";

import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Paragraph, {
  ParagraphType,
  ParagraphVariant,
} from "../../components/typography/Paragraph";

const Location = ({ slice }) => {
  return (
    <div className={"w-1/3 px-6"}>
      <Heading
        level={Level.HEADING_2}
        headingVariant={HeadingVariant.WHITE}
        className="mb-8"
      >
        {slice.primary.title[0].text}
      </Heading>

      <address className="grid not-italic grid-cols-1 gap-y-5">
        {slice?.items &&
          slice.items.map((item, i) => (
            <Paragraph
              paragraphVariant={ParagraphVariant.WHITE}
              paragraphType={ParagraphType.PARAGRAPH_4}
              key={i}
            >
              {item.location[0].text}
            </Paragraph>
          ))}
      </address>
    </div>
  );
};

export default Location;
