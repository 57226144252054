import React from "react";

import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import LinkComponent, { LinkType, LinkVariant } from "../../components/UI/Link";
import { linkResolver } from "../../../prismicio";

const Links = ({ slice, context }) => {
  switch (context.type) {
    case "header":
      return (
        <nav className="grid grid-cols-1 gap-y-7 w-fit">
          {slice?.items &&
            slice.items.map((item, i) => (
              <LinkComponent
                linkVariant={LinkVariant.WHITE}
                linkType={LinkType.LINK_4}
                link={linkResolver(item.link)}
                key={i}
              >
                {item.linkText[0].text}
              </LinkComponent>
            ))}
        </nav>
      );

    case "nav":
      return (
        <nav className="flex gap-x-5 lg:gap-x-16">
          {slice?.items &&
            slice.items.map((item, i) => (
              <LinkComponent
                linkVariant={LinkVariant.WHITE}
                linkType={LinkType.LINK_4}
                link={linkResolver(item.link)}
                key={i}
              >
                {item.linkText[0].text}
              </LinkComponent>
            ))}
        </nav>
      );

    case "footer":
      return (
        <div className={"w-1/3 px-1"}>
          <Heading
            level={Level.HEADING_2}
            headingVariant={HeadingVariant.WHITE}
            className="mb-8"
          >
            {slice.primary.title[0].text}
          </Heading>

          <nav className="grid grid-cols-2 gap-x-14	gap-y-5 w-fit">
            {slice?.items &&
              slice.items.map((item, i) => (
                <LinkComponent
                  className={"text-sm"}
                  linkVariant={LinkVariant.WHITE}
                  linkType={LinkType.LINK_4}
                  link={linkResolver(item.link)}
                  key={i}
                >
                  {item.linkText[0].text}
                </LinkComponent>
              ))}
          </nav>
        </div>
      );

    default:
      return (
        <div className={"bg-red-500"}>
          <Heading
            level={Level.HEADING_2}
            headingVariant={HeadingVariant.WHITE}
            className="mb-8"
          >
            {slice.primary.title[0].text}
          </Heading>

          <nav className="grid grid-cols-2 gap-x-14	gap-y-5 w-fit">
            {slice?.items &&
              slice.items.map((item, i) => (
                <LinkComponent
                  linkVariant={LinkVariant.WHITE}
                  linkType={LinkType.LINK_4}
                  link={linkResolver(item.link)}
                  key={i}
                >
                  {item.linkText[0].text}
                </LinkComponent>
              ))}
          </nav>
        </div>
      );
  }
};

export default Links;
