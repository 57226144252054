import React, { useCallback, useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Arrow from "../../components/UI/Arrow";
import { linkResolver } from "../../../prismicio";
import Container from "../../components/UI/Container";
import Head from "next/head";

const calculateSlidesForWidth = (w) => {
  if (w >= 1024) return 3.5;
  if (w >= 640) return 2.5;
  if (w >= 500) return 1.5;
  return 1.2;
};

const calculateSlideHeightForWidth = (w) => {
  if (w >= 1024) return 500;
  if (w >= 640) return 400;
  if (w >= 500) return 300;
  return 200;
};

const ServicesSlider = ({ slice }) => {
  const sliderRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(1.2);
  const [slideHeight, setSlideHeight] = useState(200);

  /* Setting the window width and then adding an event listener to the window that will update the window width when the
  window is resized. */
  useEffect(() => {
    const w = window.innerWidth;
    setWindowWidth(w);
    setSlidesPerView(calculateSlidesForWidth(w));
    setSlideHeight(calculateSlideHeightForWidth(w));
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  /* This is a hook that is called when the windowWidth changes. */
  useEffect(() => {
    setSlidesPerView(calculateSlidesForWidth(windowWidth));
    setSlideHeight(calculateSlideHeightForWidth(windowWidth));
  }, [windowWidth]);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Container>
      <div className="mb-10 relative">
        <div className="flex justify-between items-center">
          <Heading
            level={Level.HEADING_1}
            headingVariant={HeadingVariant.MARINE}
            className="w-fit"
          >
            {slice?.primary?.title[0]?.text}
          </Heading>
          <div className="hidden -top-1/2 right-0 sm:block space-x-2 order-last py-2">
            {slice?.items.length >= slidesPerView && (
              <>
                <button
                  aria-label="Vorige"
                  className="prev-arrow hover:cursor-pointer bg-transparent"
                  onClick={handlePrev}
                  style={{ filter: "drop-shadow(1px 1px 3px rgba(0,0,0,0.1)" }}
                >
                  <div className="clip-path-arrow-left bg-white py-4 px-5">
                    <Arrow styles="align-middle -rotate-180"></Arrow>
                  </div>
                </button>

                <button
                  aria-label="Volgende"
                  className="next-arrow hover:cursor-pointer bg-transparent "
                  onClick={handleNext}
                  style={{ filter: "drop-shadow(-1px 1px 3px rgba(0,0,0,0.1)" }}
                >
                  <div className="clip-path-arrow-right bg-white py-4 px-5">
                    <Arrow styles="align-middle"></Arrow>
                  </div>
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="relative lg:overflow-x-hidden">
        <Swiper
          ref={sliderRef}
          spaceBetween={27}
          slidesPerView={slidesPerView}
          loop={false}
          lazy={true}
          style={{ overflow: "visible" }}
        >
          {slice?.items &&
            slice.items.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="mx-auto w-fit">
                  <a href={linkResolver(item?.link)}>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={`${item?.img?.url}&width=360`}
                      alt={item?.img?.alt}
                      width="346"
                      height="245"
                    />

                    {item?.title[0]?.text && (
                      <Heading
                        level={Level.HEADING_3}
                        headingVariant={HeadingVariant.MARINE}
                        className="mt-5"
                      >
                        {item?.title[0]?.text}
                      </Heading>
                    )}
                  </a>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>

      <div className="flex sm:hidden gap-x-2 order-last mt-6 justify-end">
        <button
          aria-label="Vorige"
          className="prev-arrow hover:cursor-pointer bg-transparent"
          onClick={handlePrev}
          style={{ filter: "drop-shadow(1px 1px 3px rgba(0,0,0,0.1)" }}
        >
          <div className="clip-path-arrow-left bg-white py-4 px-5">
            <Arrow styles="align-middle -rotate-180"></Arrow>
          </div>
        </button>

        <button
          aria-label="Volgende"
          className="next-arrow hover:cursor-pointer bg-transparent "
          onClick={handleNext}
          style={{ filter: "drop-shadow(-1px 1px 3px rgba(0,0,0,0.1)" }}
        >
          <div className="clip-path-arrow-right bg-white py-4 px-5">
            <Arrow styles="align-middle"></Arrow>
          </div>
        </button>
      </div>
    </Container>
  );
};

export default ServicesSlider;
