import React, { useContext, useEffect } from "react";

import Image from "next/image";
import { isStructuredTextEmpty, linkResolver } from "../../../prismicio";

import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Paragraph, {
  ParagraphVariant,
} from "../../components/typography/Paragraph";

import { MainLayoutContext } from "../../components/layout/MainLayout";

import Button, { ButtonVariant } from "../../components/UI/Button";
import LinkComponent, { LinkType, LinkVariant } from "../../components/UI/Link";
import Logo from "../../components/UI/Logo";
import MobileOverlayMenu from "../../components/UI/MobileOverlayMenu";
import ScrollIndicator from "../../components/ScrollIndicator";
import Head from "next/head";

const Header = ({ slice, context }) => {
  const { setMobileMenuOpen, currentPath } = useContext(MainLayoutContext);
  const links = context?.globalSettings?.data?.slices?.filter(
    (slice) => slice.slice_type === "navigation"
  )[0];

  const manipulateHeaderBackground = () => {
    const headerOverlay = document.querySelector(".header-overlay");

    const scrollY = window.scrollY > 0 ? window.scrollY : 0;
    let headerHeight =
      document.getElementsByTagName("header")[0]?.getBoundingClientRect()
        .height - 223;
    let percentage = Math.round((scrollY / (headerHeight / 100)) * 100) / 100;
    let op = Math.round((scrollY / (headerHeight / 100)) * 100) / 1000;

    let opacity2 = percentage <= 0.85 ? 0.85 + op / 5 : 0.85 + op / 50;

    // Apply the calculated darkness to the body element
    headerOverlay.style.backgroundColor = "rgba(6, 37, 63, " + opacity2 + ")";
  };

  /**
   * If the scroll position is greater than the height of the header minus the height of the navigation bar, then remove
   * the invisible and opacity-0 classes from the navigation bar. Otherwise, add the invisible and opacity-0 classes to the
   * navigation bar
   */
  let lastScrollPos = 0;
  const toggleHeader = () => {
    const nav = document.querySelector(".navigation");
    const header = document.querySelector("header :first-child");
    const scrollPos = window.scrollY;

    if (scrollPos >= header.offsetHeight - nav.clientHeight) {
      // Always visible on large screens
      nav.classList.add("lg:translate-y-0");

      // On smaller screens, make it invisible when scrolling down and visible when scrolling up
      if (scrollPos < lastScrollPos) {
        nav.classList.remove("-translate-y-full");
        nav.classList.add("translate-y-0");
      } else {
        nav.classList.add("-translate-y-full");
        nav.classList.remove("translate-y-0");
      }
    } else {
      nav.classList.add("-translate-y-full");
      nav.classList.remove("translate-y-0");
      nav.classList.remove("lg:translate-y-0");
    }

    lastScrollPos = scrollPos;
  };

  useEffect(() => {
    window.addEventListener("scroll", manipulateHeaderBackground);
    window.addEventListener("scroll", toggleHeader);

    return () => {
      window.removeEventListener("scroll", manipulateHeaderBackground);
      window.removeEventListener("scroll", toggleHeader);
    };
  });

  return (
    <div className="relative z-50 antialiased">
      {/*Fixed navbar*/}
      <nav className="navigation bg-brand-marine z-40 fixed px-8 w-full -translate-y-full transition-all">
        <div className="container py-8 hidden lg:flex justify-between">
          <Logo />
          <div className="flex ml-auto gap-x-12">
            {links &&
              links?.items?.map((item, i) => {
                const urlIsActive =
                  currentPath.indexOf(linkResolver(item.link)) > -1;
                return (
                  <LinkComponent
                    linkVariant={LinkVariant.NAV_LINK}
                    linkType={LinkType.LINK_5}
                    link={linkResolver(item.link)}
                    notification={{
                      count: context?.jobsCount,
                      enabled: item?.show_vacancies,
                    }}
                    key={i}
                  >
                    {urlIsActive ? (
                      <span className="underline decoration-2 underline-offset-8">
                        {item?.label[0]?.text}
                      </span>
                    ) : (
                      <span>{item?.label[0]?.text}</span>
                    )}
                  </LinkComponent>
                );
              })}
          </div>
        </div>
        <div className="container py-8 flex lg:hidden flex-row items-center">
          <Logo />
          <button
            className="ml-auto lg:hidden relative z-10"
            onClick={() => setMobileMenuOpen()}
          >
            <Image
              src={"/img/hamburger-icon.svg"}
              height={30}
              width={30}
              alt={"menu icon"}
              quality={100}
            />
          </button>
        </div>
      </nav>

      {/*Full height navbar*/}
      <Head>
        <link
          rel="preload"
          as="image"
          href={slice?.primary?.background?.url}
        ></link>
      </Head>
      <div
        className="bg-cover bg-center w-full bg-no-repeat relative p-8 pb-24"
        style={{ backgroundImage: `url('${slice?.primary?.background?.url}')` }}
      >
        <div
          className="header-overlay w-full h-full absolute inset-0 transition-opacity"
          style={{ backgroundColor: "rgba(6, 37, 63, 0.85)" }}
        />
        <div className="container flex flex-row items-center">
          <Logo />
          <button
            className="ml-auto lg:hidden relative z-10"
            onClick={() => setMobileMenuOpen()}
          >
            <Image
              src={"/img/hamburger-icon.svg"}
              height={30}
              width={30}
              alt={"menu icon"}
              quality={100}
            />
          </button>
        </div>
        <div className="container relative flex flex-row mt-20">
          <aside className="flex flex-col place-content-between lg:w-3/12">
            <ul className="space-y-6 hidden lg:block">
              {links?.items?.map((item, i) => {
                const urlIsActive =
                  currentPath.indexOf(linkResolver(item.link)) > -1;
                return (
                  <li key={`navigation-item-loop-${i}`}>
                    <LinkComponent
                      linkVariant={LinkVariant.NAV_LINK}
                      linkType={LinkType.LINK_5}
                      link={linkResolver(item.link)}
                      notification={{
                        count: context?.jobsCount,
                        enabled: item?.show_vacancies,
                      }}
                      key={i}
                    >
                      {urlIsActive ? (
                        <span className="underline decoration-2 underline-offset-8">
                          {item?.label[0]?.text}
                        </span>
                      ) : (
                        <span>{item?.label[0]?.text}</span>
                      )}
                    </LinkComponent>
                  </li>
                );
              })}
            </ul>
            <ScrollIndicator className="hidden lg:block h-52 mt-8" />
          </aside>
          {/*Header content*/}
          <div className="flex flex-col items-start w-full">
            {slice?.primary?.subheader && (
              <div className="mb-7 text-base text-xl sm:text-2xl relative text-brand-blue-light">
                {slice?.primary?.subheader[0]?.text}
                <span className="absolute -bottom-[4px] left-0 bg-brand-blue-light w-8 h-0.5"></span>
              </div>
            )}

            {slice?.primary?.title &&
              slice?.primary?.title?.map((item, i) => (
                // default to displaydot false for now for story NOTW-1576, was earlier:
                // i == slice.primary.title.length - 1 ? true : false
                <Heading
                  level={Level.HEADER_HEADING_1}
                  headingVariant={HeadingVariant.WHITE}
                  className={`w-fit mb-1`}
                  tag={"h1"}
                  key={i}
                >
                  {item.text}
                </Heading>
              ))}

            {!isStructuredTextEmpty(slice?.primary?.description) && (
              <div className="mt-6 sm:mt-10">
                <Paragraph
                  paragraphVariant={ParagraphVariant.WHITE}
                  className="text-base sm:text-xl font-base"
                >
                  {slice?.primary?.description[0]?.text}
                </Paragraph>
              </div>
            )}

            {slice?.items?.length > 0 && (
              <ul className="mt-8 sm:mt-12 space-y-4">
                {slice?.items?.map((item, index) => {
                  const icon = item.icon;
                  const text = item.text;

                  return (
                    <li
                      key={index}
                      className="flex flex-row items-center space-x-4"
                    >
                      <Image
                        className="pointer"
                        src={icon?.url}
                        alt={icon?.alt}
                        width={20}
                        height={20}
                        quality={100}
                      />
                      <Paragraph
                        paragraphVariant={ParagraphVariant.WHITE}
                        className="text-base sm:text-xl font-base"
                      >
                        {text}
                      </Paragraph>
                    </li>
                  );
                })}
              </ul>
            )}
            {slice?.primary?.button && slice?.primary?.label !== null && (
              <div className="mt-9 sm:mt-11 sm:mb-4">
                <Button
                  buttonVariant={ButtonVariant.BUTTON_1}
                  link={linkResolver(slice.primary.button)}
                  className="inline-block"
                >
                  {slice?.primary?.label}
                </Button>
              </div>
            )}
            <ScrollIndicator className="block lg:hidden h-32" />
          </div>
        </div>
      </div>
      <MobileOverlayMenu context={context} links={links} />
    </div>
  );
};

export default Header;
