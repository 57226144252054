import React, { useEffect, useState } from "react";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Paragraph, {
  ParagraphVariant,
} from "../../components/typography/Paragraph";
import Button, { ButtonVariant } from "../../components/UI/Button";
import Image from "next/image";
import Container from "../../components/UI/Container";
import { linkResolver } from "../../../prismicio";
import { classNames } from "../../utils/classNames";
import { useForm } from "react-hook-form";

const CallToAction = ({ slice }) => {
  const inputStyles =
    "w-full pl-2 border-brand-light-gray border border-solid text-sm text-brand-marine disabled:text-gray-500 placeholder:font-extralight focus:border-brand-blue-light focus:outline-none";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const [sending, setSending] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [path, setPath] = useState("");

  useEffect(() => {
    const currentPath = window.location.href;
    setValue("page", currentPath);
    setPath(currentPath);
  }, [path]);

  const onSubmit = (data) => {
    // Prevent submit spam
    if (sending) {
      return;
    }

    setSending(true);
    fetch("/api/forms/cta", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const resJson = await res.json();
        if (res.ok) {
          setSuccessMsg(
            "Bedankt, we nemen zo snel mogelijk contact met je op!"
          );
          setSuccess(true);
          setError("");
          reset();
          return;
        }
        setSuccessMsg("");
        if (resJson.issues) {
          setError(slice?.primary?.validation_error_message);
          setSuccess(false);
          return;
        }
        if (resJson.message) {
          setError(slice?.primary?.generic_error_message);
          setSuccess(false);
        }
      })
      .catch((err) => {
        console.error("An error has occurred ", err);
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <Container className="relative">
      {slice.variation === "default" && (
        <div className="grid grid-cols-12 items-center -mx-11">
          <div
            className="col-span-12 lg:col-span-5 py-12"
            style={{ filter: "drop-shadow(-4px 6px 9px rgba(0,0,0,0.1))" }}
          >
            {/*This is a fallback for the image on mobile.*/}
            <div className="-mx-8 relative lg:hidden">
              <Image
                src={slice?.primary?.img?.url}
                alt={slice?.primary?.img?.alt}
                width={slice?.primary?.img?.dimensions?.width}
                height={slice?.primary?.img?.dimensions?.height}
                quality={100}
              />
            </div>

            <div className="p-11 pr-16 bg-white -mt-16 relative lg:my-0 lg:clip-path-cta-text">
              {slice?.primary?.title ? (
                <Heading
                  level={Level.HEADING_1}
                  headingVariant={HeadingVariant.MARINE}
                  className="w-fit mb-6"
                >
                  {slice?.primary?.title[0]?.text}
                </Heading>
              ) : (
                <></>
              )}
              {slice?.primary?.description?.map((item, i) => (
                <Paragraph
                  paragraphVariant={ParagraphVariant.MARINE}
                  key={i}
                  className={`${
                    i == slice?.primary?.description?.length - 1
                      ? "mb-10 lg:mb-24"
                      : "mb-8"
                  }`}
                >
                  {item.text}
                </Paragraph>
              ))}

              {slice?.primary?.link && slice?.primary?.linkTitle ? (
                <Button
                  buttonVariant={ButtonVariant.BUTTON_1}
                  className="inline-block"
                  link={linkResolver(slice.primary.link)}
                >
                  {slice?.primary?.linkTitle}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
          {slice?.primary?.img && (
            <div
              className="hidden absolute top-0 bottom-0 -z-50 overflow-hidden h-full w-9/12 lg:block"
              style={{ right: "-3%" }}
            >
              <Image
                src={slice?.primary?.img?.url}
                alt={slice?.primary?.img?.alt}
                style={{ objectFit: "cover" }}
                className="clip-path-global"
                quality={100}
                fill={true}
                sizes={slice?.primary?.img?.dimensions?.width}
              />
            </div>
          )}
        </div>
      )}
      {slice.variation === "form" && (
        <div className="grid grid-cols-12 items-center -mx-11">
          <div
            className="col-span-12 lg:col-span-6 py-12"
            style={{ filter: "drop-shadow(-4px 6px 9px rgba(0,0,0,0.1))" }}
          >
            {/*This is a fallback for the image on mobile.*/}
            <div className="-mx-8 relative lg:hidden">
              <Image
                src={slice?.primary?.img?.url}
                alt={slice?.primary?.img?.alt}
                width={slice?.primary?.img?.dimensions?.width}
                height={slice?.primary?.img?.dimensions?.height}
                quality={100}
              />
            </div>

            <div className="p-11 pr-16 bg-white -mt-16 relative lg:my-0 lg:clip-path-cta-text">
              {slice?.primary?.title ? (
                <Heading
                  level={Level.HEADING_1}
                  headingVariant={HeadingVariant.MARINE}
                  className="w-fit mb-6"
                >
                  {slice?.primary?.title[0]?.text}
                </Heading>
              ) : (
                <></>
              )}
              {slice?.primary?.description?.map((item, i) => (
                <Paragraph
                  paragraphVariant={ParagraphVariant.MARINE}
                  key={i}
                  className="mb-8"
                >
                  {item.text}
                </Paragraph>
              ))}

              {success ? (
                <div className="flex flex-col">
                  <div className="col-span-12">
                    <Paragraph
                      paragraphVariant={ParagraphVariant.MARINE}
                      className="mb-8 font-bold"
                    >
                      {successMsg}
                    </Paragraph>
                  </div>
                </div>
              ) : (
                <form
                  className="flex flex-col gap-4"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {success && (
                    <div className="col-span-12">
                      <div className="rounded-md bg-green-50 p-4">
                        <p className="text-sm font-medium text-green-800">
                          {successMsg}
                        </p>
                      </div>
                    </div>
                  )}

                  <label
                    htmlFor="firstname"
                    className="text-sm text-brand-marine"
                  >
                    <span className="font-bold">Naam</span>
                  </label>

                  <input
                    type="text"
                    id="name"
                    name="name"
                    className={classNames(
                      inputStyles,
                      errors.email ? "border-red-500" : ""
                    )}
                    placeholder="Naam/bedrijfsnaam"
                    onFocus={(e) => {
                      e.target.classList.add("valid:border-brand-marine");
                    }}
                    {...register("name", { required: "Vul dit veld in" })}
                    disabled={sending}
                  />

                  <label htmlFor="email" className="text-sm text-brand-marine">
                    <span className="font-bold">Telefoonnummer</span>
                  </label>

                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className={classNames(
                      inputStyles,
                      errors.phone ? "border-red-500" : ""
                    )}
                    placeholder="+31 6 123 456 78"
                    onFocus={(e) => {
                      e.target.classList.add("valid:border-brand-marine");
                    }}
                    {...register("phone", { required: "Vul dit veld in" })}
                    disabled={sending}
                  />

                  {error && (
                    <div className="col-span-12">
                      <Paragraph
                        paragraphVariant={ParagraphVariant.RED}
                        className="mb-16"
                      >
                        <span className="inline-block w-6 h-6 text-center bg-brand-red text-white rounded-full font-bold mr-3.5">
                          !
                        </span>
                        {error}
                      </Paragraph>
                    </div>
                  )}

                  <Button
                    type="submit"
                    buttonVariant={ButtonVariant.BUTTON_1}
                    tag={"button"}
                    className="w-fit mt-4"
                  >
                    {sending
                      ? "Verzenden..."
                      : slice?.primary?.button_text || "Neem contact op!"}
                  </Button>
                </form>
              )}
            </div>
          </div>
          {slice?.primary?.img && (
            <div
              className="hidden absolute top-0 bottom-0 -z-50 overflow-hidden h-full w-9/12 lg:block"
              style={{ right: "-3%" }}
            >
              <Image
                src={slice?.primary?.img?.url}
                alt={slice?.primary?.img?.alt}
                style={{ objectFit: "cover" }}
                className="clip-path-global"
                quality={100}
                fill={true}
                sizes={slice?.primary?.img?.dimensions?.width}
              />
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default CallToAction;
