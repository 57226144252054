import React from "react";
import ServiceItem from "../../components/ServiceItem";

const Service = ({ slice }) => {
  return (
    <ServiceItem
      title={slice.primary.title[0].text}
      items={slice.items}
    ></ServiceItem>
  );
};

export default Service;
