import React from "react";
import Image from "next/image";

import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import { serializeRichText } from "../../utils/HTMLSerializer";

const ImageAndText = ({ slice }) => {
  return (
    <section className="container max-w-screen">
      <div className="relative flex flex-col -mx-8 lg:my-20 lg:flex-row">
        <div className="relative z-20 lg:clip-path-cta-text lg:min-w-5/12 lg:max-w-5/12 lg:w-5/12 lg:-mr-40">
          <Image
            src={slice?.primary?.img?.url}
            alt={slice?.primary?.img.alt}
            width={slice?.primary?.img?.dimensions?.width}
            height={slice?.primary?.img?.dimensions?.height}
            quality={100}
          />
        </div>
        <div className="flex grow p-8 -mt-2 bg-brand-text-background lg:clip-path-global lg:p-0 lg:-my-20 lg:justify-center lg:w-6/12">
          <div className="flex flex-col gap-y-20 lg:p-24 lg:pl-48 justify-center">
            <div>
              <Heading
                level={Level.HEADING_3}
                headingVariant={HeadingVariant.MARINE}
                className="w-fit mb-3"
              >
                {slice?.items[0]?.header[0]?.text}
              </Heading>
              {slice?.items && (
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: serializeRichText(slice?.items[0]?.text),
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageAndText;
