import React from "react";
import Heading, {
  Level,
  HeadingVariant,
} from "../../components/typography/Heading";
import Paragraph, {
  ParagraphVariant,
} from "../../components/typography/Paragraph";
import Button, { ButtonVariant } from "../../components/UI/Button";
import Image from "next/image";
import Container from "../../components/UI/Container";
import { linkResolver } from "../../../prismicio";

const CallToActionRight = ({ slice }) => (
  // <section className='relative'>
  //     <div className='container grid grid-cols-12 items-center max-w-[1304px] h-[40.375rem] relative'>
  //         <div className='absolute top-0 bottom-0 -z-50' style={{left: "-20%"}}>
  //             {slice?.primary?.img &&
  //                 <Image src={slice?.primary?.img?.url} alt={slice?.primary?.img?.alt}
  //                        width={slice?.primary?.img?.dimensions?.width}
  //                        height={slice?.primary?.img?.dimensions?.height} className="clip-path-global"/>
  //             }
  //         </div>
  //         <div className=' w-[630px] col-end-7 ml-34 z-1' style={{ filter: "drop-shadow(-4px 6px 9px rgba(0,0,0,0.1))"}}>
  //             <div className='clip-path-cta-text py-11 pl-11 pr-16 bg-white'>
  //                 {
  //                     slice?.primary?.title ?
  //                         <Heading
  //                             displayDot
  //                             level={Level.HEADING_1}
  //                             headingVariant={HeadingVariant.MARINE}
  //                             className="w-fit  mb-6"
  //                         >
  //                             {slice?.primary?.title[0]?.text}
  //                         </Heading>
  //                         :
  //                         <Heading
  //                             displayDot
  //                             level={Level.HEADING_1}
  //                             headingVariant={HeadingVariant.MARINE}
  //                             className="w-fit  mb-6"
  //                         >
  //                             Test title
  //                         </Heading>
  //                 }
  //
  //                 {slice?.primary?.description?.map((item, i) =>
  //                     <Paragraph paragraphVariant={ParagraphVariant.MARINE} key={i}
  //                                className={`${i == slice?.primary?.description?.length - 1 ? "mb-24" : "mb-8"} text-left`}>
  //                         {item.text}
  //                     </Paragraph>
  //                 )
  //                 }
  //
  //                 {
  //                     slice?.primary?.link && slice?.primary?.linkTitle
  //                         ? <Button buttonVariant={ButtonVariant.BUTTON_1}
  //                                   link={slice.primary.link.url}>{slice?.primary?.linkTitle}</Button>
  //                         : <Button buttonVariant={ButtonVariant.BUTTON_1} link={"#"}>Test</Button>
  //                 }
  //             </div>
  //         </div>
  //     </div>
  // </section>
  <Container className="relative">
    <div className="grid grid-cols-12 items-center -mx-11">
      <div
        className="col-span-12 lg:col-start-7 xl:col-start-8 lg:col-span-6 xl:col-span-5 py-12"
        style={{ filter: "drop-shadow(-4px 6px 9px rgba(0,0,0,0.1))" }}
      >
        <div className="lg:clip-path-cta-text-reverse py-11 pr-11 pl-16 bg-white">
          <div className="lg:hidden mb-10">
            <Image
              src={slice?.primary?.img?.url}
              alt={slice?.primary?.img?.alt}
              width={slice?.primary?.img?.dimensions?.width}
              height={slice?.primary?.img?.dimensions?.height}
              quality={100}
            />
          </div>

          {slice?.primary?.title ? (
            <Heading
              level={Level.HEADING_1}
              headingVariant={HeadingVariant.MARINE}
              className="w-fit  mb-6"
            >
              {slice?.primary?.title[0]?.text}
            </Heading>
          ) : (
            <></>
          )}

          {slice?.primary?.description?.map((item, i) => (
            <Paragraph
              paragraphVariant={ParagraphVariant.MARINE}
              key={i}
              className={`${
                i == slice?.primary?.description?.length - 1
                  ? "mb-10 sm:mb-24"
                  : "mb-8"
              } text-sm sm:text-base`}
            >
              {item.text}
            </Paragraph>
          ))}

          {slice?.primary?.link && slice?.primary?.linkTitle ? (
            <Button
              buttonVariant={ButtonVariant.BUTTON_1}
              className="inline-block"
              link={linkResolver(slice.primary.link)}
            >
              {slice?.primary?.linkTitle}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
      {slice?.primary?.img && (
        <div
          className="hidden lg:block absolute top-0 bottom-0 -z-50 overflow-hidden h-full w-9/12"
          style={{ left: "-3%" }}
        >
          <Image
            src={slice?.primary?.img?.url}
            alt={slice?.primary?.img?.alt}
            style={{ objectFit: "cover" }}
            className="clip-path-global"
            quality={100}
            fill
            sizes={slice?.primary?.img?.dimensions?.width}
          />
        </div>
      )}
    </div>
  </Container>
);

export default CallToActionRight;
