import React from "react";
import Image from "next/image";
import Container from "../../components/UI/Container";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import LinkComponent, { LinkType, LinkVariant } from "../../components/UI/Link";
import { linkResolver } from "../../../prismicio";

const Logos = ({ slice }) => {
  return (
    <Container>
      {slice?.primary?.title?.length > 0 && (
        <Heading
          level={Level.HEADING_1}
          headingVariant={HeadingVariant.MARINE}
          className="w-fit"
        >
          {slice?.primary?.title[0]?.text}
        </Heading>
      )}

      <div className="grid grid-cols-12 gap-6 mt-11 -mx-2">
        {slice?.items &&
          slice.items.map((item, i) => (
            <div
              style={{ filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.1))" }}
              className="col-span-6 lg:col-span-3"
              key={`logo-${i}`}
            >
              <div className="clip-path-global h-33 bg-white logo h-full flex justify-center items-center py-12 lg:py-11 px-4 lg:px-4">
                <div className="h-full lg:h-2/3 w-full relative">
                  {item?.link ? (
                    <a
                      className="relative inline-block h-full w-full"
                      href={linkResolver(item.link)}
                    >
                      <Image
                        className="object-contain"
                        src={item.img.url}
                        alt={item.img.alt}
                        fill
                        sizes={`
                          (min-width: 640px) 150px,
                          (min-width: 768px) 300px`}
                        quality={100}
                      />
                    </a>
                  ) : (
                    <Image
                      className="object-contain"
                      src={item.img.url}
                      alt={item.img.alt}
                      fill
                      sizes={`
                          (min-width: 640px) 150px,
                          (min-width: 768px) 300px`}
                      quality={100}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </Container>
  );
};

export default Logos;
