import React from "react";
import ServiceItem from "../../components/ServiceItem";
import Container from "../../components/UI/Container";

const Services = ({ context }) => {
  return (
    <section className="bg-brand-light-blue py-14 -mx-8 px-8">
      <Container
        tag="div"
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-16 xl:gap-y-24 gap-x-8"
      >
        {context?.globalSettings?.data?.slices?.map((item, index) => {
          if (item?.slice_type === "service") {
            return (
              <ServiceItem
                key={index}
                title={item?.primary?.title[0]?.text}
                items={item?.items}
              />
            );
          }
        })}
      </Container>
    </section>
  );
};

export default Services;
