import React from "react";
import { classNames } from "../utils/classNames";
import { linkResolver } from "../../prismicio";

interface ProjectProps {
  project: {
    url: string;
    data: {
      title: [
        {
          text: string;
        }
      ];
      thumbnail: {
        alt: string;
        vertical: {
          url: string;
        };
      };
    };
  };
  className: string;
}

const Project: React.FC<ProjectProps> = ({ project, className }) => {
  return (
    <div className={classNames("w-full relative group", className)}>
      <figure className="relative">
        <div className="aspect-[3/5] md:aspect-[4/6] overflow-hidden">
          <a
            href={linkResolver(project)}
            className="relative block w-full h-full"
          >
            <div className="absolute -left-6 right-0 h-full origin-top-left lg:skew-x-[2deg]">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={project.data.thumbnail.vertical.url}
                alt={project.data.thumbnail.alt}
                className="h-full top-0 object-cover object-center relative transition-all duration-300 ease-in-out group-hover:transform group-hover:scale-110"
              />
            </div>
            <figcaption className="absolute bottom-0 -left-1 mb-12 bg-white px-6 py-3 group-hover:pl-12 transition-all duration-300 ease-in-out">
              <strong className="origin-top-left lg:skew-x-[2deg]">
                {project.data.title[0].text}
              </strong>
            </figcaption>
          </a>
        </div>
      </figure>
    </div>
  );
};

export default Project;
