import React from "react";
import Link from "next/link";

const Logo: React.FC = () => {
  return (
    <Link href="/">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 105 21"
        width="105"
        height="21"
        className="relative cursor-pointer fill-current text-white"
      >
        <title>Notive</title>
        <path d="M92.064 0H88v21h4.064v-.002L98 21v-4.008h-5.936v-4.487h5.603V8.498h-5.603V4.013H98V0zM11.057 0v11.283L3.943 4.027v-.004L0 0v21h3.943V9.721l7.114 7.256L15 21V0zm17.944 4.365c-7.751 0-7.751 12.462 0 12.462 7.75 0 7.75-12.462 0-12.462m0-4.365c13.332 0 13.332 21 0 21-13.334 0-13.335-21 0-21m17.993 21V4.003H42V0h14v4.003h-4.994V21z" />
        <path d="M59.499 20.992h4.045V-.002h-4.045z" />
        <path d="M75.46 19.902 67.45.018h4.464l2.571 6.483 1.407 4.031 1.38-3.895L79.87.018h4.465l-8.01 19.884z" />
        <path d="M75.891 20.992v-1.09h-.432" />
        <path d="M75.891 20.992v-1.09h.434" />
        <path d="M100.985 12.467H105V8.49h-4.015z" />
      </svg>
    </Link>
  );
};

export default Logo;
