import React from "react";
import { classNames } from "../../utils/classNames";

export enum ParagraphType {
  PARAGRAPH_1,
  PARAGRAPH_2,
  PARAGRAPH_3,
  PARAGRAPH_4,
  PARAGRAPH_5,
  PARAGRAPH_6,
  PARAGRAPH_7,
}

interface ParagraphProps {
  paragraphVariant: ParagraphVariant;
  children: React.ReactNode;
  tag?: string;
  className?: string;
  paragraphType?: ParagraphType;
  id?: string;
  style?: object;
}

export enum ParagraphVariant {
  MARINE,
  WHITE,
  BLUE,
  LIGHT_BLUE,
  RED,
  GREEN,
}

const PARAGRAPH_TYPE_MAPS: Record<ParagraphType, string> = {
  [ParagraphType.PARAGRAPH_1]: "leading-6 text-left tracking-normal",
  [ParagraphType.PARAGRAPH_2]:
    "font-extrabold text-lg leading-bold-paragraph text-bold-paragraph text-center",
  [ParagraphType.PARAGRAPH_3]: "leading-6 font-bold tracking-normal",
  [ParagraphType.PARAGRAPH_4]: "text-sm",
  [ParagraphType.PARAGRAPH_5]: "text-base font-light",
  [ParagraphType.PARAGRAPH_6]: "text-xs font-bold",
  [ParagraphType.PARAGRAPH_7]: "",
};

const PARAGRAPH_VARIANT_MAPS: Record<ParagraphVariant, string> = {
  [ParagraphVariant.MARINE]: "text-brand-marine",
  [ParagraphVariant.WHITE]: "text-white",
  [ParagraphVariant.BLUE]: "text-brand-blue",
  [ParagraphVariant.LIGHT_BLUE]: "text-brand-blue-light",
  [ParagraphVariant.RED]: "text-brand-red",
  [ParagraphVariant.GREEN]: "text-brand-green",
};

const Paragraph: React.FC<ParagraphProps> = ({
  paragraphVariant,
  tag,
  children,
  className,
  paragraphType,
  id,
  style,
}) => {
  if (!tag) tag = "div";
  if (paragraphType == undefined) paragraphType = ParagraphType.PARAGRAPH_1;

  const Tag = tag as keyof JSX.IntrinsicElements;

  return (
    <Tag
      className={classNames(
        PARAGRAPH_VARIANT_MAPS[paragraphVariant],
        PARAGRAPH_TYPE_MAPS[paragraphType],
        className
      )}
      id={id ? id : ""}
      style={style ? style : {}}
    >
      {children}
    </Tag>
  );
};

export default Paragraph;
