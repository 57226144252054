import React, { useRef, useCallback, useState, useEffect } from "react";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Project from "../../components/Project";
import LinkComponent, { LinkType, LinkVariant } from "../../components/UI/Link";
import { Swiper, SwiperSlide } from "swiper/react";
import Container from "../../components/UI/Container";
import Arrow from "../../components/UI/Arrow";
import { linkResolver } from "../../../prismicio";

const HighlightedProjects = ({ slice }) => {
  const sliderRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Container>
      {slice?.primary?.title[0]?.text && (
        <Heading
          level={Level.HEADING_1}
          headingVariant={HeadingVariant.MARINE}
          className="mb-6 w-fit"
        >
          {slice.primary.title[0].text}
        </Heading>
      )}
      <div className="relative origin-top-left lg:-skew-x-[2deg] lg:-mr-4">
        <Swiper
          ref={sliderRef}
          slidesPerView={windowWidth >= 1024 ? 3 : windowWidth >= 768 ? 2 : 1}
          spaceBetween={24}
          loop={true}
          lazy={false}
        >
          {slice.projects.map((item, i) => (
            <SwiperSlide key={i}>
              <Project project={item} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="absolute top-1/2 z-50 flex w-full justify-between lg:hidden z-20">
          <button
            role="presentation"
            tabIndex={-1}
            className="-ml-6 bg-transparent prev-arrow hover:cursor-pointer"
            onClick={handlePrev}
            style={{ filter: "drop-shadow(1px 1px 3px rgba(0,0,0,0.1)" }}
          >
            <div className="bg-white p-3 lg:clip-path-arrow-left">
              <Arrow styles="align-middle -rotate-180"></Arrow>
            </div>
          </button>
          <button
            role="presentation"
            tabIndex={-1}
            className="-mr-6 bg-transparent next-arrow hover:cursor-pointer"
            onClick={handleNext}
            style={{ filter: "drop-shadow(-1px 1px 3px rgba(0,0,0,0.1)" }}
          >
            <div className="bg-white p-3 lg:clip-path-arrow-right">
              <Arrow styles="align-middle"></Arrow>
            </div>
          </button>
        </div>
      </div>
      <LinkComponent
        className="mt-6"
        linkVariant={LinkVariant.BLUE}
        link={linkResolver(slice.primary.link)}
        linkType={LinkType.LINK_3}
        displayArrowRight={true}
      >
        <strong>{slice.primary.linkTitle}</strong>
      </LinkComponent>
    </Container>
  );
};

export default HighlightedProjects;
