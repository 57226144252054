import React from "react";
import Heading, { HeadingVariant, Level } from "./typography/Heading";
import LinkComponent, { LinkVariant } from "./UI/Link";
import { linkResolver } from "../../prismicio";

interface ServiceItemProps {
  title: string;
  items: { link: { url: string }; linkTitle: string }[];
}

const ServiceItem: React.FC<ServiceItemProps> = ({ title, items }) => {
  return (
    <div>
      {title && (
        <Heading
          level={Level.HEADING_1}
          headingVariant={HeadingVariant.MARINE}
          className="w-fit mb-3 lg:mb-6"
        >
          {title}
        </Heading>
      )}

      {items ? (
        <ul className="grid gap-y-3.5">
          {items.map(
            (item: { link: { url: string }; linkTitle: string }, i: number) => (
              <li key={i}>
                <LinkComponent
                  linkVariant={LinkVariant.BLUE}
                  link={linkResolver(item.link)}
                  displayArrowLeft
                >
                  {item.linkTitle}
                </LinkComponent>
              </li>
            )
          )}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ServiceItem;
