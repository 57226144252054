import React from "react";

import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Paragraph, {
  ParagraphType,
  ParagraphVariant,
} from "../../components/typography/Paragraph";

import Image from "next/image";

const Contact = ({ slice }) => {
  return (
    <div className={"w-1/3 px-2 flex flex-col items-flex-end justify-flex-end"}>
      <Heading
        level={Level.HEADING_2}
        headingVariant={HeadingVariant.WHITE}
        className="mb-8"
      >
        {slice?.primary?.title[0]?.text}
      </Heading>

      <nav className="grid grid-cols-1	gap-y-5">
        {slice?.items &&
          slice?.items?.map((item, i) => (
            <div key={i} className="flex items-center">
              <div className="mr-3.5">
                <Image
                  src={item?.icon?.url}
                  alt={item?.icon?.alt}
                  width={item?.icon?.dimensions?.width}
                  height={item?.icon?.dimensions?.height}
                  quality={100}
                />
              </div>

              <Paragraph
                paragraphVariant={ParagraphVariant.WHITE}
                paragraphType={ParagraphType.PARAGRAPH_4}
                className="mr-14"
              >
                {item?.text[0]?.text}
              </Paragraph>
            </div>
          ))}
      </nav>
    </div>
  );
};

export default Contact;
