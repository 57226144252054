import Image from "next/image";
import { linkResolver } from "../../../prismicio";
import React, { FC, useContext } from "react";
import { MainLayoutContext } from "../layout/MainLayout";
import { classNames } from "../../utils/classNames";
import Logo from "./Logo";

const MobileOverlayMenu: FC = ({ context, links }: any) => {
  const { mobileMenuOpen, setMobileMenuOpen, currentPath } =
    useContext(MainLayoutContext);

  return (
    <div
      className={classNames(
        "mobile-overlay bg-[#06243f] w-full h-full z-50 p-8 fixed inset-0 lg:hidden",
        mobileMenuOpen ? "" : "hidden"
      )}
    >
      <div className="container flex flex-col h-full space-y-24">
        <div className="h-fit w-full flex flex-row justify-between items-center">
          <div className="w-full flex justify-between items-center">
            <div className={"w-32 h-auto relative"}>
              <Logo />
            </div>

            <button className={"pointer"}>
              <Image
                onClick={() => setMobileMenuOpen()}
                src={"/img/close-icon.svg"}
                height={30}
                width={30}
                alt={"menu icon"}
                quality={100}
              />
            </button>
          </div>
        </div>
        <div className="flex flex-col space-y-8">
          {links &&
            links?.items?.map((item: any, i: number) => {
              const urlIsActive =
                currentPath.indexOf(linkResolver(item.link)) > -1;
              return (
                <a
                  className="text-medium text-white font-semibold space-x-2.5 flex justify-around link uppercase"
                  href={linkResolver(item.link)}
                  key={i}
                >
                  <div className="flex">
                    {urlIsActive ? (
                      <span className="underline decoration-2 underline-offset-8">
                        {item?.label[0]?.text}
                      </span>
                    ) : (
                      <span>{item?.label[0]?.text}</span>
                    )}

                    {item?.show_vacancies && (
                      <span className="bg-white text-black text-[0.6rem] flex items-center justify-center -mr-6 w-4 h-4 -mt-2 ml-2 rounded-full">
                        {context?.jobsCount?.toString()}
                      </span>
                    )}
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default MobileOverlayMenu;
