import React from "react";
import Container from "../../components/UI/Container";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import { serializeRichText } from "../../utils/HTMLSerializer";

const DefaultTextSliceColumns = ({ slice }) => {
  return (
    <Container className="max-w-screen-1.5xl grid grid-cols-12 ">
      <div className="col-span-12 lg:col-span-4 mr-8">
        {slice?.primary?.title && (
          <Heading
            level={Level.HEADING_1}
            headingVariant={HeadingVariant.MARINE}
            className="w-fit mb-6 leading-8"
          >
            {slice?.primary?.title[0]?.text}
          </Heading>
        )}
      </div>
      <div
        className={`col-span-12 grid grid-cols-1 lg:col-span-8 lg:grid-cols-${slice?.primary?.colomn_quantity}`}
      >
        {slice.items.map((item, i) => {
          return (
            <div
              key={i}
              className="grid gap-y-6 mb-8"
              dangerouslySetInnerHTML={{
                __html: serializeRichText(item.body),
              }}
            ></div>
          );
        })}
      </div>
    </Container>
  );
};

export default DefaultTextSliceColumns;
