import React, { useEffect } from "react";

import Image from "next/image";

import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Paragraph, {
  ParagraphType,
  ParagraphVariant,
} from "../../components/typography/Paragraph";
import Container from "../../components/UI/Container";
import TeamData from "./team.json";

const Team = () => {
  useEffect(() => {
    const test = document.createElement("div");
    test.style.overflow = "clip";
    const isOverflowClipSupported = test.style.overflow === "clip";

    if (!isOverflowClipSupported) {
      document.querySelectorAll(".overflow-x-clip").forEach((el) => {
        el.classList.add("overflow-hidden");
        el.classList.remove("overflow-x-clip");
      });
    }
  }, []);

  return (
    <Container>
      <ul className="grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 xl:gap-32">
        {TeamData.team?.map((item, i) => {
          return (
            <li
              className="group w-fit mx-auto rotate-[5deg]"
              key={`team-member-${i}`}
            >
              <div className="relative w-[250px] md:w-[350px] overflow-x-clip">
                <div className="bg-brand-light-blue-nav h-[250px] md:h-[350px]"></div>
                <div className="-rotate-[5deg] absolute -bottom-5 right-0 -left-5 w-[325px] h-[325px] md:w-[435px] md:h-[435px] transition-all origin-bottom group-hover:transform group-hover:scale-110">
                  <Image
                    src={item?.img?.url}
                    alt={item?.img?.alt}
                    width={item?.img?.dimensions?.width * 2}
                    height={item?.img?.dimensions?.height * 2}
                    quality={100}
                  />
                </div>
              </div>
              <div className="relative flex items-center justify-between pt-2 -mr-2 pr-2 bg-white">
                <Heading
                  level={Level.HEADING_1}
                  headingVariant={HeadingVariant.MARINE}
                >
                  {item.name}
                </Heading>
                <Paragraph
                  paragraphVariant={ParagraphVariant.LIGHT_BLUE}
                  paragraphType={ParagraphType.PARAGRAPH_4}
                  className="ml-auto"
                >
                  <strong>{item.position}</strong>
                </Paragraph>
              </div>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default Team;
