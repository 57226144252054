import React from "react";

interface ScrollIndicatorProps {
  className: string;
}

const ScrollIndicator: React.FC<ScrollIndicatorProps> = ({ className }) => {
  return (
    <div className={`overflow-clip ${className}`}>
      <div className="flex items-center rotate-90 origin-bottom-left leading-none">
        <div className="text-center text-brand-blue-light mr-4 uppercase">
          Scroll
        </div>
        <div className="relative w-24 overflow-x-clip">
          <div
            className="bg-brand-blue-light animate-scroll"
            style={{ height: 1 }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ScrollIndicator;
