import React, { useState, useEffect } from "react";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import Paragraph, {
  ParagraphVariant,
} from "../../components/typography/Paragraph";
import Button, { ButtonVariant } from "../../components/UI/Button";
import Image from "next/image";
import Container from "../../components/UI/Container";
import { classNames } from "../../utils/classNames";
import { useForm } from "react-hook-form";

const CallToActionSmall = ({ slice }) => {
  const [sending, setSending] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [path, setPath] = useState("");

  const inputStyles =
    "w-full pl-2 border-brand-light-gray border border-solid text-sm text-brand-marine disabled:text-gray-500 placeholder:font-extralight focus:border-brand-blue-light focus:outline-none";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    // Prevent submit spam
    if (sending) {
      return;
    }

    setSending(true);
    fetch("/api/forms/cta", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const resJson = await res.json();
        if (res.ok) {
          setSuccessMsg(slice?.primary?.success_message);
          setSuccess(true);
          setError("");
          reset();
          return;
        }
        setSuccessMsg("");
        if (resJson.issues) {
          setError(slice?.primary?.validation_error_message);
          setSuccess(false);
          return;
        }
        if (resJson.message) {
          setError(slice?.primary?.generic_error_message);
          setSuccess(false);
        }
      })
      .catch((err) => {
        console.error("An error has occurred ", err);
      })
      .finally(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    const currentPath = window.location.href;
    setValue("page", currentPath);
    setPath(currentPath);
  }, [path]);

  return (
    <Container tag="aside" className="relative">
      <div className="relative origin-top-left -ml-8 pl-8 -mr-12 pr-12 bg-brand-marine md:-skew-x-[12deg]">
        {slice?.primary?.img?.url !== undefined ? (
          <figure className="absolute w-full h-full -ml-8 pl-8 -mr-12 after:bg-brand-marine after:bg-opacity-60 after:absolute after:w-full after:h-full after:-mr-12 after:-ml-8">
            <Image
              className="absolute w-full h-full object-cover"
              src={slice?.primary?.img?.url}
              alt={slice?.primary?.img?.alt}
              style={{ objectFit: "cover" }}
              quality={100}
              fill={true}
              sizes={slice?.primary?.img?.dimensions?.width}
            />
          </figure>
        ) : (
          ""
        )}

        <div className="origin-top-left md:skew-x-[12deg]">
          <div className="relative pt-12 pb-14">
            {slice.primary.title ? (
              <div className="font-extrabold text-2xl leading-8 uppercase  text-brand-marine w-fit mb-4 mx-auto text-white">
                {slice?.primary?.title[0]?.text}
              </div>
            ) : (
              ""
            )}
            {success ? (
              <div className="flex flex-col items-center">
                <div className="col-span-12">
                  <Heading
                    level={Level.HEADING_1}
                    headingVariant={HeadingVariant.MARINE}
                    className="text-center mb-6 uppercase text-white"
                  >
                    {slice?.primary?.confirmation_message}
                  </Heading>
                  <Paragraph
                    paragraphVariant={ParagraphVariant.MARINE}
                    className="text-white text-center"
                  >
                    {successMsg}
                  </Paragraph>
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col items-center gap-8">
                  {slice?.primary?.description?.map((item, i) => (
                    <Paragraph
                      paragraphVariant={ParagraphVariant.MARINE}
                      key={i}
                      className={`text-white
                  `}
                    >
                      {item.text}
                    </Paragraph>
                  ))}
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col md:flex-row gap-6 md:gap-4 max-w-xl w-full justify-center"
                  >
                    <div className="w-full">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className={classNames(
                          inputStyles,
                          errors.name ? "border-red-500" : ""
                        )}
                        placeholder="Naam/bedrijfsnaam"
                        onFocus={(e) => {
                          e.target.classList.add("valid:border-brand-marine");
                        }}
                        {...register("name", { required: "Vul dit veld in" })}
                        disabled={sending}
                      />
                      {errors.name && (
                        <p className="h-8 -mb-8 text-red-500">
                          {errors.name.message}
                        </p>
                      )}
                    </div>

                    <div className="w-full">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className={classNames(
                          inputStyles,
                          errors.phone ? "border-red-500" : ""
                        )}
                        placeholder="+31 6 123 456 78"
                        onFocus={(e) => {
                          e.target.classList.add("valid:border-brand-marine");
                        }}
                        {...register("phone", { required: "Vul dit veld in" })}
                        disabled={sending}
                      />
                      {errors.phone && (
                        <p className="h-8 -mb-8 text-red-500">
                          {errors.phone.message}
                        </p>
                      )}
                    </div>

                    <input
                      id="page"
                      name="page"
                      className={"hidden"}
                      type="hidden"
                      value={path}
                      {...register("page")}
                    />

                    <div>
                      <Button
                        type="submit"
                        buttonVariant={ButtonVariant.BUTTON_1}
                        tag={"button"}
                        className="w-36 py-1.5 px-9 flex-shrink-0"
                      >
                        {sending
                          ? "Verzenden..."
                          : slice?.primary?.button_title}
                      </Button>
                    </div>
                  </form>

                  {error && (
                    <div>
                      <Paragraph paragraphVariant={ParagraphVariant.RED}>
                        <span className="inline-block w-6 h-6 text-center bg-brand-red text-white rounded-full font-bold mr-3.5">
                          !
                        </span>
                        {error}
                      </Paragraph>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CallToActionSmall;
