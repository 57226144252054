// Code generated by Slice Machine. DO NOT EDIT.

import Breadcrumb from "./Breadcrumb";
import CallToAction from "./CallToAction";
import CallToActionRight from "./CallToActionRight";
import CallToActionSmall from "./CallToActionSmall";
import Contact from "./Contact";
import ContactForm from "./ContactForm";
import DefaultTextSlice from "./DefaultTextSlice";
import DefaultTextSliceColumns from "./DefaultTextSliceColumns";
import GlobalFooter from "./GlobalFooter";
import GlobalNavigation from "./GlobalNavigation";
import GlobalServices from "./GlobalServices";
import Header from "./Header";
import HighlightedProject from "./HighlightedProject";
import HighlightedProjects from "./HighlightedProjects";
import ImageAndText from "./ImageAndText";
import Jobs from "./Jobs";
import Links from "./Links";
import Location from "./Location";
import Logos from "./Logos";
import Quotes from "./Quotes";
import Service from "./Service";
import ServicesSlider from "./ServicesSlider";
import Slideshow from "./Slideshow";
import Socials from "./Socials";
import Team from "./Team";
import UniqueSellingPoints from "./UniqueSellingPoints";

export {
  Breadcrumb,
  CallToAction,
  CallToActionRight,
  CallToActionSmall,
  Contact,
  ContactForm,
  DefaultTextSlice,
  DefaultTextSliceColumns,
  GlobalFooter,
  GlobalNavigation,
  GlobalServices,
  Header,
  HighlightedProject,
  HighlightedProjects,
  ImageAndText,
  Jobs,
  Links,
  Location,
  Logos,
  Quotes,
  Service,
  ServicesSlider,
  Slideshow,
  Socials,
  Team,
  UniqueSellingPoints,
};

export const components = {
  breadcrumb: Breadcrumb,
  call_to_action: CallToAction,
  call_to_action_right: CallToActionRight,
  call_to_action_small: CallToActionSmall,
  contact: Contact,
  contact_form: ContactForm,
  default_text_slice: DefaultTextSlice,
  default_text_slice_columns: DefaultTextSliceColumns,
  footer: GlobalFooter,
  navigation: GlobalNavigation,
  services: GlobalServices,
  header: Header,
  highlighted_project: HighlightedProject,
  highlighted_projects: HighlightedProjects,
  image_and_text: ImageAndText,
  jobs: Jobs,
  links: Links,
  location: Location,
  logos: Logos,
  quotes: Quotes,
  service: Service,
  services_slider: ServicesSlider,
  slideshow: Slideshow,
  socials: Socials,
  team: Team,
  unique_selling_points: UniqueSellingPoints,
};
