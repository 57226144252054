import React from "react";
import { classNames } from "../../utils/classNames";

export enum Level {
  HEADING_1,
  HEADING_2,
  HEADING_3,
  HEADING_4,
  HEADING_5,
  HEADING_6,
  HEADER_HEADING_1,
  HEADER_HEADING_2,
}

export enum HeadingVariant {
  WHITE,
  GREEN,
  DARK_GREEN,
  RED,
  BLACK,
  GRAY,
  MARINE,
}

interface HeadingProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  tag?: keyof JSX.IntrinsicElements;
  children: React.ReactNode;
  level?: Level;
  headingVariant: HeadingVariant;
  color?: string;
  dotAlignment?: string;
  className?: string;
  displayDot?: boolean;
}

export const HEADING_TYPE_MAPS: Record<Level, string> = {
  [Level.HEADING_1]: "font-extrabold text-2xl leading-8 uppercase",
  [Level.HEADING_2]: "font-extrabold text-base",
  [Level.HEADING_3]: "font-extrabold text-xl uppercase",
  [Level.HEADING_4]: "font-extrabold text-lg",
  [Level.HEADING_5]: "font-bold text-md",
  [Level.HEADING_6]: "text-sm ",
  [Level.HEADER_HEADING_1]: "font-extrabold text-4xl sm:text-5xl heading",
  [Level.HEADER_HEADING_2]: "font-extrabold text-2xl heading",
};

export const HEADING_VARIANT_MAPS: Record<HeadingVariant, string> = {
  [HeadingVariant.WHITE]: " text-white",
  [HeadingVariant.GREEN]: " text-primary",
  [HeadingVariant.DARK_GREEN]: " text-primary-900",
  [HeadingVariant.RED]: " text-secondary",
  [HeadingVariant.BLACK]: " text-brand-gray-500",
  [HeadingVariant.GRAY]: " text-brand-gray-500",
  [HeadingVariant.MARINE]: " text-brand-marine",
};

const Heading: React.FC<HeadingProps> = ({
  level,
  headingVariant,
  tag = "h2",
  className,
  children,
  dotAlignment,
  displayDot,
  ...rest
}) => {
  if (!level) level = Level.HEADING_1;
  const Tag = tag as keyof JSX.IntrinsicElements;
  const dotHeight =
    level === Level.HEADER_HEADING_1
      ? " w-2 h-2"
      : classNames("w-1 h-1", dotAlignment === "center" ? "md:mb-[3px]" : "");

  return (
    <Tag
      {...rest}
      className={classNames(
        HEADING_TYPE_MAPS[level],
        HEADING_VARIANT_MAPS[headingVariant],
        className,
        displayDot ? "relative display-dot" : ""
      )}
    >
      {children}

      {displayDot && (
        <span
          className={`inline-block text-brand-blue-light bg-brand-blue-light ${dotHeight}`}
        />
      )}
    </Tag>
  );
};

export default Heading;
