import React from "react";

interface ArrowProps {
  styles?: string;
}

const arrowStyles =
  "bg-arrow-right flex-none w-6 h-6 inline-block bg-cover bg-no-repeat bg-center";

const Arrow: React.FC<ArrowProps> = ({ styles }) => {
  return <div className={`${arrowStyles} ${styles}`}></div>;
};

export default Arrow;
