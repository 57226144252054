import React from "react";
import { classNames } from "../../utils/classNames";

interface ArrowExtendedProps {
  className: string;
}

const ArrowExtended: React.FC<ArrowExtendedProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 10"
    width="54px"
    height="10px"
    className={classNames("link__arrow--right", className)}
  >
    <g fill="#0099CE">
      <path d="m49.613.21.094.083 4 4a.927.927 0 0 1 .097.112l.071.11.054.114.035.105.03.148L54 5l-.003.075-.017.126-.03.111-.044.111-.052.098-.074.104-.073.082-4 4a1 1 0 0 1-1.497-1.32l.083-.094L50.585 6H39a1 1 0 0 1-.117-1.993L39 4h11.585l-2.292-2.293A1 1 0 0 1 49.51.14l.102.07Z" />
      <rect width={45} height={2} y={4} rx={1} />
    </g>
  </svg>
);

export default ArrowExtended;
