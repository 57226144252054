import { linkResolver } from "../../prismicio";
import * as prismicR from "@prismicio/richtext";
import { RichTextFunctionSerializer } from "@prismicio/richtext";
import * as prismicH from "@prismicio/helpers";
import { HTMLFunctionSerializer } from "@prismicio/helpers";
import { RichTextField } from "@prismicio/types";

export const HTMLSerializer:
  | HTMLFunctionSerializer
  | RichTextFunctionSerializer<string> = prismicR.wrapMapSerializer({
  heading1: ({ children }) =>
    `<h1 class="font-extrabold text-2xl leading-8 uppercase text-brand-marine mt-2 -mb-4">${children}</h1>`,
  heading2: ({ children }) =>
    `<h2 class="font-extrabold text-base text-brand-marine mt-2 -mb-4" >${children}</h2>`,
  heading3: ({ children }) =>
    `<h3 class="font-extrabold text-xl uppercase text-brand-marine mt-4 -mb-4">${children}</h3>`,
  heading4: ({ children }) =>
    `<h4 class="font-extrabold text-lg text-brand-marine -mb-6">${children}</h4>`,
  heading5: ({ children }) =>
    `<h5 class="font-bold text-md text-brand-marine -mb-6">${children}</h5>`,
  heading6: ({ children }) =>
    `<h6 class="font-bold text-base text-brand-marine -mb-6">${children}</h6>`,
  paragraph: ({ children }) =>
    `<p class='relative text-brand-marine'>${children}</p>`,
  preformatted: ({ node }) =>
    `<pre class="text-sm bg-brand-light-blue overflow-x-scroll relative space-y-8 -mx-8 lg:clip-path-global px-6 py-6 lg:py-8 lg:px-12">${node.text}</pre>`,
  strong: ({ children }) => `<strong>${children}</strong>`,
  em: ({ children }) => `<em>${children}</em>`,
  listItem: ({ children }) =>
    `<li class='relative text-brand-marine'> ${children} </li>`,
  oListItem: ({ children }) => `<li class="pl-2">${children}</li>`,
  list: ({ children }) =>
    `<ul class='pl-4 list-disc text-brand-marine'>${children}</ul>`,
  oList: ({ children }) =>
    `<ol class='pl-4 list-decimal text-brand-marine'>${children}</ol>`,

  hyperlink: ({ node, children }) => {
    // @ts-ignore
    const target = node.data?.target
      ? // @ts-ignore
        `target="${node.data?.target}" rel="noopener"`
      : "";
    const url = linkResolver(node.data);
    const link = `<a ${target} class="text-brand-blue-light hover:text-brand-faded-blue transition-colors font-bold" href="${
      url ?? node.data.url
    }">${children}</a>`;
    return link;
  },
  label: ({ node, children }) => {
    return `<span class="${node.data.label}">${children}</span>`;
  },
  span: ({ text }) => (text ? text : ""),
});

/**
 * Converts rich text field to serialized html.
 * @param field
 */
export function serializeRichText(field: RichTextField) {
  // @ts-ignore
  return prismicH.asHTML(field, null, HTMLSerializer);
}
