import React from "react";
import LinkComponent, { LinkVariant } from "../../components/UI/Link";
import Heading, {
  HeadingVariant,
  Level,
} from "../../components/typography/Heading";
import { serializeRichText } from "../../utils/HTMLSerializer";
import { isStructuredTextEmpty, linkResolver } from "../../../prismicio";
import Container from "../../components/UI/Container";

const DefaultTextSlice = ({ slice }) => (
  <Container className="max-w-screen-1.5xl grid grid-cols-12 ">
    <div className="col-span-12 lg:col-span-4 mr-8">
      {slice?.primary?.title && (
        <Heading
          level={Level.HEADING_1}
          headingVariant={HeadingVariant.MARINE}
          className="w-fit mb-6 leading-8"
        >
          {slice?.primary?.title[0]?.text}
        </Heading>
      )}
    </div>

    <div className="col-span-12 lg:col-span-8 shrink-1 [word-break:break-word]">
      {!isStructuredTextEmpty(slice?.primary?.body) && (
        <div
          className="rte-styles grid gap-y-6"
          dangerouslySetInnerHTML={{
            __html: serializeRichText(slice?.primary?.body),
          }}
        ></div>
      )}

      {slice?.items[0]?.linkTitle && (
        <ul
          className={`${
            !isStructuredTextEmpty(slice?.primary?.body) ? "mt-8" : ""
          } ${
            slice?.items?.length > 1
              ? "grid sm:grid-cols-2 gap-y-6 lg:gap-x-44 lg:w-fit"
              : ""
          }`}
        >
          {slice.items.map((item, i) => {
            return (
              <li key={i}>
                <LinkComponent
                  linkVariant={LinkVariant.BLUE}
                  link={linkResolver(item?.link)}
                  displayArrowRight={true}
                >
                  {item?.linkTitle}
                </LinkComponent>
              </li>
            );
          })}
        </ul>
      )}

      {slice?.primary?.list && (
        <ul
          className="grid gap-y-3 mt-6 pl-4"
          dangerouslySetInnerHTML={{
            __html: serializeRichText(slice?.primary?.list),
          }}
        ></ul>
      )}
    </div>
  </Container>
);

export default DefaultTextSlice;
