import React from "react";

const BreadCrumbArrow: React.FC = () => {
  return (
    <span className={"w-5 h-5 flex items-center justify-center"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="5px"
        height="8px"
        viewBox="0 0 5 8"
      >
        <path fill="none" stroke="#06253F" d="m1 .49 3.5 3.5L1 7.49" />
      </svg>
    </span>
  );
};

export default BreadCrumbArrow;
