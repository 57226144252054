import React from "react";
import { classNames } from "../../utils/classNames";

export enum ButtonVariant {
  BUTTON_1,
}

interface ButtonProps {
  children: React.ReactNode;
  link?: string;
  className?: string;
  buttonVariant: ButtonVariant;
  tag?: keyof JSX.IntrinsicElements;
  props?: any;
  type: "button" | "submit";
}

const BUTTON_TYPE_MAPS: Record<ButtonVariant, string> = {
  [ButtonVariant.BUTTON_1]:
    "font-bold text-white bg-brand-green py-3 pl-5 pr-[1.55rem]",
};

const Button: React.FC<ButtonProps> = ({
  children,
  link = "",
  className,
  buttonVariant,
  tag,
  type,
  ...props
}) => {
  if (!tag) tag = "a";
  const Tag = tag as keyof JSX.IntrinsicElements;

  return (
    <Tag
      href={link}
      className={classNames(
        BUTTON_TYPE_MAPS[buttonVariant],
        "hover:bg-brand-dark-green transition ease-in-out delay-[30ms] clip-path-button",
        className
      )}
      // @ts-ignore
      type={type}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default Button;
