import React from "react";
import LinkComponent, { LinkType, LinkVariant } from "../../components/UI/Link";
import Image from "next/image";
import { linkResolver } from "../../../prismicio";

const Socials = ({ slice }) => {
  return (
    <div className="grid grid-cols-3 gap-x-3.5">
      {slice?.items &&
        slice.items.map((item, i) => (
          <LinkComponent
            linkVariant={LinkVariant.WHITE}
            linkType={LinkType.LINK_4}
            link={linkResolver(item.link)}
            key={i}
          >
            <Image
              src={item.icon.url}
              alt={item.icon.alt}
              width={item.icon.dimensions.width}
              height={item.icon.dimensions.height}
              quality={100}
            />
          </LinkComponent>
        ))}
    </div>
  );
};

export default Socials;
