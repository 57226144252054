import React from "react";
import { classNames } from "../../utils/classNames";

interface ContainerProps {
  children: React.ReactNode;
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
}

const Container: React.FC<ContainerProps> = ({
  children,
  tag = "section",
  className,
}) => {
  const Tag = tag as keyof JSX.IntrinsicElements;
  return (
    <Tag className={classNames("container", className)}>
      {/*<Tag className={classNames("container max-w-screen-1.5xl px-8 xl:px-16 2xl:px-0 px-2.5 sm:px-8", className)}>*/}
      {children}
    </Tag>
  );
};

export default Container;
